<template>
  <n-form-confirmation
    key="createForm"
    @submit="submit"
    :form="$refs.createForm"
    :disabled="invalid"
  >
    <n-input :fields="fields" v-model="data"></n-input>
    <b-row>
      <b-col cols="12" class="text-right">
        <n-button-loading
          type="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-1 mr-1"
          :loading="loading"
          :disabled="invalid"
          v-if="$can('create', 'whole-sale')"
        >
          {{ $t('button.saveChanges') }}
        </n-button-loading>
        <n-button-loading
          type="button"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          :loading="loading"
          class="mt-1"
          @submit="back"
        >
          {{ $t('button.back') }}
        </n-button-loading>
      </b-col>
    </b-row>
  </n-form-confirmation>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading'
import NFormConfirmation from '@/components/NFormConfirmation'
import NInput from '@/components/NInput'
import FormInput from './formInput'

const WholeSaleRepository = Repository.get('wholeSale')

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        amount: '',
      },
      loading: false,
    }
  },
  methods: {
    submit() {
      this.loading = true
      WholeSaleRepository.deduct(this.$route.params.id, this.data)
        .then((response) => {
          this.$toast.success(response?.data?.message)
          this.$router.push({
            name: 'list-whole-sale',
          })
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message)
        })
        .then(() => {
          this.loading = false
        })
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const fields = [
      {
        key: 'amount',
        label: 'Amount',
        type: 'currency',
        rules: 'required',
      },
    ]

    return { fields }
  },
}
</script>
